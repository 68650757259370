import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss']
})
export class SpecialOfferComponent  {
  @Input() isMobileDevice: boolean;
  constructor() { }

  }




