import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertMessageService {

  private alertMessage$ = new Subject<string>();

  constructor() { }

  setAlertMessage(messageType: string) {
    this.alertMessage$.next(messageType);
  }

  getMessage(): Observable<string> {
    return this.alertMessage$.asObservable();
  }
}
