import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertMessageService } from './alert-message.service';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  showAlert = false;

  messageType: string;
  messageText: string;
  private messageSubscription: Subscription;

  constructor(private alertService: AlertMessageService, private renderer: Renderer2) { }

  ngOnInit() {
    this.messageSubscription = this.alertService.getMessage().subscribe(data => {
      this.showAlertF();

      this.messageType = data;
      switch (data) {
        case 'email':
          this.messageText = 'Wkrótce skontaktuje się <span class="nowrap">z Tobą</span> nasz konsultant. <br class="mobileNone">Jeżeli wyraziłeś zgodę na działania marketingowe <span class="nowrap">w przyszłości</span>, zostaniesz poproszony o ich potwierdzenie. <br>Gdyby nie udało nam się <span class="nowrap">z Tobą</span> skontaktować telefonicznie, wyślemy <span class="nowrap">e-mail</span> lub SMS, <span class="nowrap">z prośbą</span> o ich potwierdzenie, poprzez kliknięcie <span class="nowrap">w przesłany</span> link.';
          break;
        case 'phone':
          this.messageText = 'Wkrótce skontaktuje się <span class="nowrap">z Tobą</span> nasz konsultant. <br class="mobileNone">Jeżeli wyraziłeś zgodę na działania marketingowe <span class="nowrap">w przyszłości</span>, zostaniesz poproszony o ich potwierdzenie. <br>Gdyby nie udało nam się <span class="nowrap">z Tobą</span> skontaktować telefonicznie, wyślemy <span class="nowrap">e-mail</span> lub SMS, <span class="nowrap">z prośbą</span> o ich potwierdzenie, poprzez kliknięcie <span class="nowrap">w przesłany</span> link.';
          break;
        case 'all':
          this.messageText = 'Wkrótce skontaktuje się <span class="nowrap">z Tobą</span> nasz konsultant. <br class="mobileNone">Jeżeli wyraziłeś zgodę na działania marketingowe <span class="nowrap">w przyszłości</span>, zostaniesz poproszony o ich potwierdzenie. <br>Gdyby nie udało nam się <span class="nowrap">z Tobą</span> skontaktować telefonicznie, wyślemy <span class="nowrap">e-mail</span> lub SMS, <span class="nowrap">z prośbą</span> o ich potwierdzenie, poprzez kliknięcie <span class="nowrap">w przesłany</span> link.';
          break;
        case 'default':
          this.messageText = 'Wkrótce skontaktuje się <span class="nowrap">z Tobą</span> nasz konsultant. <br class="mobileNone">Jeżeli wyraziłeś zgodę na działania marketingowe <span class="nowrap">w przyszłości</span>, zostaniesz poproszony o ich potwierdzenie. <br>Gdyby nie udało nam się <span class="nowrap">z Tobą</span> skontaktować telefonicznie, wyślemy <span class="nowrap">e-mail</span> lub SMS, <span class="nowrap">z prośbą</span> o ich potwierdzenie, poprzez kliknięcie <span class="nowrap">w przesłany</span> link.';
          break;
      }
    });

  }

  showAlertF() {
    this.showAlert = true;
    console.log(this.showAlert)
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  closeAlert() {
    this.messageType = null;
    this.renderer.setStyle(document.body, 'overflow', 'auto');

  }

}
