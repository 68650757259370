import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { FormComponent } from './form/form.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MenuComponent } from './menu/menu.component';
import { OfferFormComponent } from './offer-form/offer-form.component';
import { TestDriveFormComponent } from './test-drive-form/test-drive-form.component';
import { AboutHybridComponent } from './about-hybrid/about-hybrid.component';
import { SpecialOfferComponent } from './special-offer/special-offer.component';
import { PlugInComponent } from './plug-in/plug-in.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HybrydyMoveComponent } from './hybrydy-move/hybrydy-move.component';


@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        FooterComponent,
        OfferFormComponent,
        HomePageComponent,
        TestDriveFormComponent,
        FormComponent,
        AlertMessageComponent,
        AboutHybridComponent,
        SpecialOfferComponent,
        PlugInComponent,
        HybrydyMoveComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ScrollToModule.forRoot(),
        SlickCarouselModule,
        ReactiveFormsModule
    ],
    providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule {}
