import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-about-hybrid',
  templateUrl: './about-hybrid.component.html',
  styleUrls: ['./about-hybrid.component.scss']
})
export class AboutHybridComponent implements OnInit {
  @Input() isMobileDevice: boolean;
  constructor(
    private appService: AppService
  ) {}

  trackGAEvent(eventName: string) {
    this.appService.trackGAEventClick(eventName);
}

  ngOnInit() {
  }

}
