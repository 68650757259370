import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    HostListener
} from '@angular/core';
import { ScrollToService } from 'ng2-scroll-to-el';
import { Subscription } from 'rxjs';
import debounce from 'lodash.debounce';

import { AppService } from '../app.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

declare var $: any;

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private scrollService: ScrollToService,
        private appService: AppService
    ) {
        this.appService.isHomePage = true;
        window.scrollTo(0, 0);
    }
    showYtVideo = false;
    currentSystemId = 0;
    headerBannerSrc = '';
    headerBannerSrcFirst = '';
    headerBannerSrcSecond = '';
    isMobileDevice = false;
    isMobileDeviceSmall = false;

    sectionRefSubscription: Subscription;
    screenWidth = window.innerWidth;
    isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    slickElement: any;

    private debouncedOnScroll = debounce(
        () => this.checkOfferFormVisible(),
        100,
        {}
    );
    private isSyneriseNewsletterScrollSend = false;
    private searchParam = '';


    @ViewChild('header', { static: true }) header: ElementRef;
    @ViewChild('features', { static: true }) features: ElementRef;
    @ViewChild('superb', { static: true }) superb: ElementRef;
    @ViewChild('moreInfo', { static: true }) moreInfo: ElementRef;
    @ViewChild('capabilities', { static: true }) capabilities: ElementRef;
    @ViewChild('offerForm', { static: true }) offerForm: ElementRef;



    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.screenWidth = event.target.innerWidth;
        this.checkWidth();
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.debouncedOnScroll();
    }

    ngOnInit() {
        this.searchParam = location.search;

        document.onreadystatechange = () => {
            if (
                document.readyState === 'complete' &&
                this.searchParam.includes('section=offerForm')
            ) {
                this.appService.setNavSectionRef('offerForm');
            }
        };

        this.checkWidth();

        this.sectionRefSubscription = this.appService
            .getNavSectionRef()
            .subscribe(data => {
                if (data !== '' && this.appService.currentSectionName === '') {
                    this.scrollToSection(data);
                }
            });
    }

    ngAfterViewInit() {
        if (this.appService.currentSectionName !== '') {
            this.scrollToSection(this.appService.currentSectionName);
            this.appService.currentSectionName = '';
        }
    }

    ngOnDestroy() {
        this.sectionRefSubscription.unsubscribe();
    }

    checkWidth() {
        const headerSuperbDesktop = './assets/video/superb_header_d.mp4';
        const headerSuperbMobile = './assets/images/superb_mobile2.png';
        this.isMobileDevice = this.screenWidth < 992;
        this.isMobileDeviceSmall = this.screenWidth < 577;

        if (this.isMobileDevice) {
            this.headerBannerSrcFirst = headerSuperbMobile;
        } else {
            this.headerBannerSrcFirst = headerSuperbDesktop;
        }
    }

    scrollToSection(sectionId) {
        this.scrollService.scrollTo(this[sectionId].nativeElement, 600, -60);
    }

    goToNexSection() {
        const next = document.getElementById('features');
        next.scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    trackSyneriseEvent(
        eventType: string,
        eventName: string,
        modelName?: string
    ) {
        this.appService.trackSyneriseEvent(eventType, eventName, modelName);
    }

    trackGAEvent(eventName: string) {
        this.appService.trackGAEventClick(eventName);
    }

    private checkOfferFormVisible() {
        const rectObject = this.offerForm.nativeElement.getBoundingClientRect();
        if (rectObject.top < 110 && rectObject.bottom > window.innerHeight) {
            this.replaceUrl('offerForm');
            if (!this.isSyneriseNewsletterScrollSend) {
                this.isSyneriseNewsletterScrollSend = true;
            }
        } else {
            this.replaceUrl('home');
        }
    }

    private replaceSectionQueryParam(newSectonName: string) {
        if (location.search === '') {
            return `?section=${newSectonName}`;
        } else if (location.search.indexOf('section') === -1) {
            return `${location.search}&section=${newSectonName}`;
        } else {
            const indexOfSectionQueryLeft = location.search.indexOf('section');
            const indexOfSectionQueryRight = location.search.indexOf('&', indexOfSectionQueryLeft);
            let leftStr = location.search.substr(0, indexOfSectionQueryLeft);
            leftStr += `section=${newSectonName}`;
            if (indexOfSectionQueryRight !== -1) {
                leftStr += location.search.substr(indexOfSectionQueryRight);
            }
            return leftStr;
        }
    }

    private replaceUrl(newSectionName: string) {
        const newUrl = `${location.origin}${
            location.pathname
            }${this.replaceSectionQueryParam(newSectionName)}${location.hash}`;
        history.pushState(null, null, newUrl);
    }



}