import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public currentSectionName = '';
  public isHomePage = true;
  private navSectionRef$ = new BehaviorSubject<string>('header');

  getNavSectionRef() {
    return this.navSectionRef$.asObservable();
  }

  setNavSectionRef(sectionRef) {
    this.navSectionRef$.next(sectionRef);
  }

  trackGAEventClick(eventActionName: string, eventLabel?: string, eventCategory?: string) {
    const dataLayer = window['dataLayer'];
    dataLayer.push(
      {
        event: 'ga_event',
        eventCategory: eventCategory ? eventCategory : 'Campaign LP',
        eventAction: eventActionName,
        eventLabel: eventLabel ? eventLabel : '202102_hybrydy_LP'
      }
    );
  }

  trackSyneriseEvent(type: string, name: string, model?: string) {
    if (window['SR']) {
      window['SR'].event.trackCustomEvent(name, {
        'type': type,
        'domain': window.location.hostname,
        'model': model ? model : '',
        'BP': 'false'
      }, name);
    }
  }
}
