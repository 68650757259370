import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import Swal from 'sweetalert2';

import { AppService } from '../app.service';
import { AlertMessageService } from '../alert-message/alert-message.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
    form: FormGroup;
    formData = new FormData();
    isBlocked = false;
    isMoreInfoOpened = false;
    isFormSubmitted = false;
    formKey = 'newsletter';

    constructor(
        private alertService: AlertMessageService,
        private appService: AppService,
        private elem: ElementRef,
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private scrollService: ScrollToService
    ) {}

    ngOnInit() {
        this.appService.isHomePage = false;
        window.scrollTo(0, 0);
        this.initForm();
        this.appendFieldsToFormData();

    }

    onInputChange(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.value });
        this.checkApprovalsValues();
    }

    changeValue(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.checked });
        this.checkApprovalsValues();
    }

    checkApprovalsValues() {
        if (!this.form.value['gdpr-newsletter-info'] ||
            !this.form.value['gdpr-newsletter-direct-marketing'] ||
            !this.form.value['gdpr-newsletter-accept']
        ) {
            this.isBlocked = true;
        } else {
            this.isBlocked = false;
        }
    }

    closePopup() {
        this.router.navigateByUrl('');
    }

    initForm() {
        this.form = this.fb.group(
            {
                name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')]],
                surname: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')]],
                email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]],
                'gdpr-newsletter-info': false,
                'gdpr-newsletter-direct-marketing': false,
                'gdpr-newsletter-accept': false
            },
            { updateOn: 'blur' }
        );
    }

    mapApprovalsValues() {
        this.form.value['gdpr-newsletter-info'] = this.form.value['gdpr-newsletter-info'] ? true : false;
        this.form.value['gdpr-newsletter-direct-marketing'] = this.form.value['gdpr-newsletter-direct-marketing'] ? true : false;
        this.form.value['gdpr-newsletter-accept'] = this.form.value['gdpr-newsletter-accept'] ? true : false;
    }

    onSubmitSuccessForm(orderId, formNameDataLayer, formNameSynerise) {
        let dataLayer = window['dataLayer'];
        dataLayer.push({
            event: 'formularze',
            'wypelniony-formularz': formNameDataLayer,
            orderid: orderId
        });

        if (window['SyneriseTC'] !== undefined) {
            window['SyneriseTC'].sendFormData(formNameSynerise, {
                firstname: this.form.value.name,
                lastname: this.form.value.surname,
                email: this.form.value.email,
                newsletterAgreement: this.form.value['gdpr-newsletter-info'] ? 'enabled' : 'disabled',
                'gdpr-newsletter-direct-marketing': this.form.value['gdpr-newsletter-direct-marketing'] ? '1' : '0',
                'gdpr-newsletter-accept': this.form.value['gdpr-newsletter-accept'] ? '1' : '0',
                orderID: orderId
            });
        }
    }

    sendForm() {
        let apiUrl = '';
        let formNameSynerise = '';
        let formNameDataLayer = '';
        this.isFormSubmitted = true;
        this.checkApprovalsValues();

        if (this.form.valid && !this.isBlocked) {
            this.mapApprovalsValues();
            this.setValuesOfFormData();
            apiUrl = 'https://fbods2.kampanie.skoda.pl/web/handler/202001_ELEKTRYKI_LP_NL?key=er23riru8wefewfiewfief34mi43egergerge';
            formNameSynerise = `newsletter,lp,newsletterHybrydy`;
            formNameDataLayer = 'newsletter-zamowiono-201912-elektryki';

            this.http.post<{ externalId: string; status: string }>(apiUrl, this.formData).subscribe(
                data => {
                    if (data.status === 'error') {
                            Swal.fire({
                                title: 'Błąd',
                                text: 'Wystąpił błąd podczas wysyłania danych.',
                                icon: 'error',
                            });
                            return false;
                        } else {
                            this.onSubmitSuccessForm(data.externalId, formNameDataLayer, formNameSynerise);
                            this.alertService.setAlertMessage('email');
                            this.resetForm();
                            this.isBlocked = false;
                            this.isFormSubmitted = false;
                        }
                    },
                    error => {
                        Swal.fire({
                            title: 'Błąd',
                            text: 'Wystąpił błąd podczas wysyłania danych.',
                            icon: 'error',
                        });
                    }
                );
        } else {
            const invalidFields = this.elem.nativeElement.querySelectorAll('.ng-invalid');
            this.scrollService.scrollTo(invalidFields[1], 600, -70);
        }
    }

    private appendFieldsToFormData() {
        this.formData.append('formType', '');
        this.formData.append('name', '');
        this.formData.append('surname', '');
        this.formData.append('email', '');
        this.formData.append('gdpr-newsletter-info', '');
        this.formData.append('gdpr-newsletter-direct-marketing', '');
        this.formData.append('gdpr-newsletter-accept', '');
    }

    private setValuesOfFormData() {
        this.formData.set('formType', this.formKey);
        this.formData.set('name', this.form.value.name);
        this.formData.set('surname', this.form.value.surname);
        this.formData.set('email', this.form.value.email);
        this.formData.set('gdpr-newsletter-info', this.form.value['gdpr-newsletter-info']);
        this.formData.set('gdpr-newsletter-direct-marketing', this.form.value['gdpr-newsletter-direct-marketing']);
        this.formData.set('gdpr-newsletter-accept', this.form.value['gdpr-newsletter-accept']);
    }

    private resetForm() {
        this.form.reset();
        this.form.patchValue({
            name: '',
            surname: '',
            email: '',
            'gdpr-newsletter-info': false,
            'gdpr-newsletter-direct-marketing': false,
            'gdpr-newsletter-accept': false,
        });
    }
}
