import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import Swal from 'sweetalert2';

import { AlertMessageService } from '../alert-message/alert-message.service';
import { AppService } from '../app.service';

@Component({
    selector: 'app-test-drive-form',
    templateUrl: './test-drive-form.component.html',
    styleUrls: ['./test-drive-form.component.scss']
})
export class TestDriveFormComponent implements OnInit {
    form: FormGroup;
    formData = new FormData();
    isBlocked = false;
    isMoreInfoOpened = false;
    isFormSubmitted = false;
    // car = '';

    @Input() formKey: string;

    modelsToSelect = [
        {
            id: 'superb',
            name: 'Superb Combi Plug-in Hybrid ',
            iconUrl: './assets/images/form-icon/octavia_icon.png',
            formKey: 'superb',
            formName: 'oferta-superb-zamowiono-2021-elektryki'
        },
        // {
        //     id: 'octavia',
        //     name: 'Octavia iV',
        //     iconUrl: './assets/images/form-icon/octavia_iV_icon.png',
        //     formKey: 'octavia',
        //     formName: 'oferta-octavia-zamowiono-2021-elektryki'
        // },
        {
            id: 'kodiaq',
            name: 'Kodiaq Plug-in Hybrid',
            iconUrl: './assets/images/form-icon/superb_icon.png',
            formKey: 'kodiaq',
            formName: 'oferta-kodiaq-zamowiono-201912-elektryki'
        }];
    selectedModel;

    constructor(
        private appService: AppService,
        private alertService: AlertMessageService,
        private elem: ElementRef,
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private scrollService: ScrollToService
    ) {}

    ngOnInit() {
        this.appService.isHomePage = false;
        window.scrollTo(0, 0);
        this.initForm();
        this.appendFieldsToFormData();
        this.setCarModel(this.modelsToSelect[0]);
    }

    onInputChange(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.value });
        this.checkApprovalsValues();
    }

    changeValue(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.checked });
        this.checkApprovalsValues();
    }

    checkApprovalsValues() {
        if (
            (this.form.value['gdpr-answer-marketing-messages-importer'] &&
                !(
                    this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls']
                )) ||
            (!this.form.value['gdpr-answer-marketing-messages-importer'] &&
                (this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls'])) ||
            (this.form.value['gdpr-answer-email'] && !this.form.value.email)
        ) {
            this.isBlocked = true;
        } else {
            this.isBlocked = false;
        }
    }

    closePopup() {
        this.router.navigateByUrl('');
    }

    initForm() {
        this.form = this.fb.group(
            {
                name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')]],
                phoneNo: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{9}$')]],
                email: ['', [Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]],
                'gdpr-answer-marketing-messages-importer': false,
                'gdpr-answer-email': false,
                'gdpr-answer-electronic-messages': false,
                'gdpr-answer-voice-calls': false
            },
            { updateOn: 'blur' }
        );
    }

    mapApprovalsValues() {
        this.form.value['gdpr-answer-marketing-messages-importer'] = this.form.value['gdpr-answer-marketing-messages-importer'] ? 1 : 0;
        this.form.value['gdpr-answer-email'] = this.form.value['gdpr-answer-email'] ? 1 : 0;
        this.form.value['gdpr-answer-electronic-messages'] = this.form.value['gdpr-answer-electronic-messages'] ? 1 : 0;
        this.form.value['gdpr-answer-voice-calls'] = this.form.value['gdpr-answer-voice-calls'] ? 1 : 0;
    }



    sendForm() {
        // this.appService.trackSyneriseEvent('submit', 'jazda.probna');

        // if(this.formKey === 'test-drive-octavia') {
        //     this.appService.trackSyneriseEvent("LPelektrykiJazdaProbnaOctavia.send", "LPelektrykiJazdaProbnaOctaviaSend");
        // } else {
        //     this.appService.trackSyneriseEvent("LPelektrykiJazdaProbnaSuperb.send", "LPelektrykiJazdaProbnaSuperbSend");
        // }

        let apiUrl = '';
        const formName = '';
        let formNameSynerise = '';
        let formNameDataLayer = '';
        let model = '';
        this.isFormSubmitted = true;
        this.checkApprovalsValues();

        if (this.form.valid && !this.isBlocked) {
            this.mapApprovalsValues();
            this.setValuesOfFormData();

            apiUrl = 'https://fbods2.kampanie.skoda.pl/web/handler/202409_emobility_JP?key=er23riru8wefewfiewfief34mi43egergerge';
            model = this.selectedModel.id;
            formNameSynerise = `${model},jazda.probna,lp`;
            formNameDataLayer = 'jazda-probna-zamowiono-202102_hybrydy_LP_jp';

            this.http
                .post<{ externalId: string; status: string }>(
                    apiUrl,
                    this.formData
                )
                .subscribe(
                    data => {
                        if (data.status === 'error') {
                            Swal.fire({
                                title: 'Błąd',
                                text: 'Wystąpił błąd podczas wysyłania danych.',
                                icon: 'error',
                            });
                            return false;
                        } else {
                            this.onSubmitSuccessForm(data.externalId, formNameDataLayer, formNameSynerise, model);
                            this.setAlertMessageType();
                            this.resetForm();
                            this.isBlocked = false;
                            this.isFormSubmitted = false;
                        }
                    },
                    error => {
                        Swal.fire({
                            title: 'Błąd',
                            text: 'Wystąpił błąd podczas wysyłania danych.',
                            icon: 'error',
                        });
                    }
                );
        } else {
            const invalidFields = this.elem.nativeElement.querySelectorAll('.ng-invalid');
            this.scrollService.scrollTo(invalidFields[1], 600, -70);
        }
    }

    setCarModel(car) {
        this.selectedModel = car;
    }

    private setAlertMessageType() {
        if (this.form.value['gdpr-answer-email'] && !(this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('email');
        } else if (!this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('phone');
        } else if (this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('all');
        } else {
            this.alertService.setAlertMessage('default');
        }
    }

    private appendFieldsToFormData() {
        this.formData.append('formType', '');
        this.formData.append('carModel', '');
        this.formData.append('name', '');
        this.formData.append('phoneNo', '');
        this.formData.append('email', '');
        this.formData.append('gdpr-answer-marketing-messages-importer', '');
        this.formData.append('gdpr-answer-email', '');
        this.formData.append('gdpr-answer-electronic-messages', '');
        this.formData.append('gdpr-answer-voice-calls', '');
    }

    private setValuesOfFormData() {
        this.formData.set('formType', this.selectedModel.name + ' ' + 'test-drive');
        this.formData.set('carModel', this.selectedModel.formKey);
        this.formData.set('name', this.form.value.name);
        this.formData.set('phoneNo', this.form.value.phoneNo);
        this.formData.set('email', this.form.value.email);
        this.formData.set('gdpr-answer-marketing-messages-importer', this.form.value['gdpr-answer-marketing-messages-importer']);
        this.formData.set('gdpr-answer-email', this.form.value['gdpr-answer-email']);
        this.formData.set('gdpr-answer-electronic-messages', this.form.value['gdpr-answer-electronic-messages']);
        this.formData.set('gdpr-answer-voice-calls', this.form.value['gdpr-answer-voice-calls']);
    }

    onSubmitSuccessForm(orderId, formNameDataLayer, formNameSynerise, modelName) {
        const dataLayer = window['dataLayer'];
        dataLayer.push({
            event: 'formularze',
            'wypelniony-formularz': formNameDataLayer,
            orderid: orderId,
            model: modelName
        });

        dataLayer.push({
            event: 'ga_event',
            eventCategory: 'formularze',
            eventAction: 'wypełniony formularz',
            eventLabel: 'jazda-probna-zamowiono-202108-Safety'
        });

        if (window['SyneriseTC']) {
            window['SyneriseTC'].sendFormData(
                formNameSynerise,
                {
                    firstname: this.form.controls.name.value,
                    phone: this.form.controls.phoneNo.value,
                    email: this.form.controls.email.value,
                    // newsletterAgreement: this.form.controls['gdpr-answer-email'].value ? 'enabled' : 'disabled',
                    // receive_smses: this.form.controls['gdpr-answer-electronic-messages'].value ? 'true' : 'false',
                    // phonecall: this.form.controls['gdpr-answer-voice-calls'].value ? 'yes' : 'no',
                    orderID: orderId
                }
            );
        }
    }

    private resetForm() {
        this.form.reset();
        this.form.patchValue({
            name: '',
            phoneNo: '',
            email: '',
            'gdpr-answer-marketing-messages-importer': false,
            'gdpr-answer-email': false,
            'gdpr-answer-electronic-messages': false,
            'gdpr-answer-voice-calls': false
        });
    }
}
